import React from "react"
import {graphql} from "gatsby"

import SEO from "src/components/Element/seo"
import "./index.css"

export default class NotFoundPage extends React.Component {

    siteTitle = this.props.data.site.siteMetadata.title

    render() {
        return <>
            <SEO title="404 Not Found"/>
            <div className={"not-found-mask"}></div>
            <div className="main-content main-content-not-found f-lato">
                <h1 style={{fontSize: '2rem', margin: '-1.5rem 0 0', letterSpacing: '0.08rem'}}>Not Found</h1>
            </div>
        </>
    }
}

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
